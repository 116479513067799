// import { TYPE_OBJ } from '@/low_code/utils/hooks';
import { STATIC_SHOW_COMPONENT_SCHEMA } from './staticShow';
import { TEXT_EDITOR_COMPONENT_SCHEMA } from './textEditor';
import { DYNAMIC_SHOW_COMPONENT_SCHEMA } from './dynamicShow';
import { RADIO_COMPONENT_SCHEMA } from './radio';
import { CHECKBOX_COMPONENT_SCHEMA } from './checkbox';
import { COL_AREA_COMPONENT_SCHEMA } from './colArea';
import { ROW_AREA_COMPONENT_SCHEMA } from './rowArea';
import { MARK_AREA_COMPONENT_SCHEMA } from './markArea';
import { IMG_ANNOTATION_COMPONENT_SCHEMA } from './imgAnnotation';
import { TEXT_SELECTION_COMPONENT_SCHEMA } from './textSelection';
import { VIDEO_PLAYER_COMPONENT_SCHEMA } from './videoPlayer';

const TYPE_OBJ = {
  MARK: 'mark',
  COL: 'col',
  ROW: 'row',
  STATIC_SHOW: 'staticShow',
  DYNAMIC_SHOW: 'dynamicShow',
  TEXT_EDITOR: 'textEditor',
  RADIO: 'radio',
  MULTI_SELECT: 'multiSelect',
  SORT: 'sort',
  ImgAnnotation: 'imgAnnotation',
  TEXT_SELECTION: 'textSelection',
  VIDEO_PLAYER: 'videoPlayer'
} as Record<string, string>;

export default {
  [TYPE_OBJ.STATIC_SHOW]: STATIC_SHOW_COMPONENT_SCHEMA,
  [TYPE_OBJ.TEXT_EDITOR]: TEXT_EDITOR_COMPONENT_SCHEMA,
  [TYPE_OBJ.DYNAMIC_SHOW]: DYNAMIC_SHOW_COMPONENT_SCHEMA,
  [TYPE_OBJ.RADIO]: RADIO_COMPONENT_SCHEMA,
  [TYPE_OBJ.MULTI_SELECT]: CHECKBOX_COMPONENT_SCHEMA,
  [TYPE_OBJ.COL]: COL_AREA_COMPONENT_SCHEMA,
  [TYPE_OBJ.ROW]: ROW_AREA_COMPONENT_SCHEMA,
  [TYPE_OBJ.MARK]: MARK_AREA_COMPONENT_SCHEMA,
  [TYPE_OBJ.ImgAnnotation]: IMG_ANNOTATION_COMPONENT_SCHEMA,
  [TYPE_OBJ.TEXT_SELECTION]: TEXT_SELECTION_COMPONENT_SCHEMA,
  [TYPE_OBJ.VIDEO_PLAYER]: VIDEO_PLAYER_COMPONENT_SCHEMA
};

import { AxiosPromise } from 'axios';
import { sha256 } from 'js-sha256';
import request from '../../common/request';
import { User } from '../../store/account/user';
import { allowPageList } from '../../common/allowlist';

const baseUrl = '/rbac';

export interface LoginParamsType {
  nickname: string;
  username: string;
  userId: number;
  password: string;
  phone: string;
  platform: string;
  token: string;
  captcha?: string;
  roleCode?: string;
  platformDomain: string;
}

export function login(params: LoginParamsType): AxiosPromise {
  params.password = sha256(params.password);
  return request(`${baseUrl}/login`, {
    method: 'POST',
    data: params
  });
}
export function ssoLogin(params: any): AxiosPromise {
  return request(`${baseUrl}/ssoLogin`, {
    method: 'POST',
    data: params
  });
}

export interface GetUserInfoListParamsType {
  groupId?: string;
  keyword?: string;
  nickName?: string;
  phone?: string;
  userIds?: number[];
  roleId?: number; // 角色ID: 2-运营人员 3-运营人员 4-标注团队负责人 5-标注团队管理员 6-普通账号(质检|验收)
  // groupId?: number;
}

export function getUserInfoList(
  params: GetUserInfoListParamsType
): AxiosPromise {
  return request(`${baseUrl}/group/getUserInfoList`, {
    method: 'POST',
    data: params
  });
}

export function logout(): AxiosPromise {
  return request('/rbac/logout', {
    method: 'POST'
  });
}

export function fetchCaptcha(): AxiosPromise {
  return request('/rbac/captcha');
}

export function addUserInfoToStorage(userInfo: User): void {
  localStorage.setItem('nickname', userInfo.nickname);
  localStorage.setItem('token', userInfo.token);
  localStorage.setItem('username', userInfo.username);
  localStorage.setItem('userId', String(userInfo.userId));
  localStorage.setItem('groupId', String(userInfo.groupId));
  localStorage.setItem('phone', userInfo.username || userInfo.phone);
  localStorage.setItem('roleCode', userInfo.roleCode || '');
  localStorage.setItem(
    'timingCycle',
    String(userInfo.timingCycle || 1000 * 60)
  );
  localStorage.setItem('questionFlag', String(userInfo.questionFlag));
  // @ts-ignore
  localStorage.setItem('registerSource', userInfo.registerSource);
}

export function deleteUserInfoToStorage(): void {
  localStorage.removeItem('nickname');
  localStorage.removeItem('token');
  localStorage.removeItem('registerSource');
  localStorage.removeItem('username');
  localStorage.removeItem('userId');
  localStorage.removeItem('phone');
  localStorage.removeItem('roleCode');
  localStorage.removeItem('timingCycle');
  // todo
  localStorage.removeItem('questionFlag');
  localStorage.removeItem('groupId');
}

export function validateUser(
  route: { authority?: Array<string>; path: string },
  userInfo: User
): boolean {
  if (route.path) {
    if (allowPageList.includes(route.path)) {
      return true;
    }
  }
  if (!userInfo || !userInfo.token) {
    return false;
  }
  if (route.authority) {
    if (!route.authority.includes(userInfo.roleCode)) {
      return false;
    }
  }
  return true;
}

export interface MarkTeamListParamsType {
  name: string;
}

export function getMarkTeamList(params?: MarkTeamListParamsType): AxiosPromise {
  return request(`${baseUrl}/group/list`, {
    method: 'POST',
    data: params || { name: '' }
  });
}

export function getCheckUserList(): AxiosPromise {
  return request(`${baseUrl}/group/getCheckUserList`, {
    method: 'GET'
  });
}

export function get1V1UserInfo(): AxiosPromise {
  return request('/rbac/get1V1UserInfo', {
    method: 'GET'
  });
}

export function getOperationUserList(): AxiosPromise {
  return request(`${baseUrl}/group/getOperationCommonUserList`, {
    method: 'GET'
  });
}

export function getPriceTemplateList(params: any): AxiosPromise {
  return request('/manager/price/templatePriceList', {
    method: 'POST',
    data: params
  });
}

export function getTemplateList(): AxiosPromise {
  return request('/manager/project/markTypeList', {
    method: 'GET'
  });
}

export function getSubPriceList(params: any): AxiosPromise {
  return request(`/manager/project/markTemplateList/${params.markType}`, {
    method: 'GET'
  });
}
// 机器人人寰列表
export function getUserAccount(value: string): AxiosPromise {
  return request(`/manager/project/getUserAccount?name=${value}`, {
    method: 'GET'
  });
}

export function getManagerInfo(): AxiosPromise {
  return request(`${baseUrl}/getManagerInfo`, {
    method: 'GET'
  });
}

export function dictionaryList(params: any): AxiosPromise {
  return request(`/manager/project/dictionaryList/${params.roleCode}`, {
    method: 'GET'
  });
}

export interface getYachUserListType {
  key: string;
  pageNum: number;
  pageSize: number;
}
export function getYachUserList(params: getYachUserListType): AxiosPromise {
  return request('/manager/project/getYachUser', {
    method: 'POST',
    data: params
  });
}
